<!-- <div class="menu">
  <button type="button" appDropdown="menu">Open Menu</button>
</div> -->
<header class="header">
  <div class="headerInner">
    <div class="logo">
      <a routerLink="/home"><img src="./assets/images/logo.png" alt=""></a>
    </div>
    <div class="rightNav">
      <nav class="navbar navbar-expand-lg">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img src="./assets/images/menu.svg" alt="">
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li><a routerLink="/home" routerLinkActive="active">Home</a></li>
            <li><a routerLink="/about" routerLinkActive="active">ABOUT US</a></li>
            <li><a routerLink="/service" routerLinkActive="active">SERVICES </a></li>
            <li><a routerLink="/join-our-team" routerLinkActive="active">JOIN OUR TEAM</a></li>
            <li><a routerLink="/contact" routerLinkActive="active">CONTACT US</a></li>

          </ul>

        </div>
      </nav>
    </div>
  </div>
</header>