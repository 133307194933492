<footer class="footer">
  <div class="container">
    <div class="footerTop">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <h3>Home</h3>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3>About Us </h3>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3>Services</h3>
          <ul>
            <li><a routerLink="/trans-service">Transportaion Services</a> </li>
            <li><a routerLink="/expe-service">Expedited Services</a> </li>
            <li><a routerLink="/intermodal-services">Intermodal Services</a> </li>
            <li><a routerLink="/vehicle-tracking">Vehicle Tracking</a> </li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3> Contact Us</h3>
        </div>
      </div>
    </div>
    <div class="footerBot">
      <div class="socialMedia">
        <ul>
          <li><a href="https://www.instagram.com/" target="_blank"><i class="fa fa-instagram"
                aria-hidden="true"></i></a></li>
          <li><a href="https://twitter.com/" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
          <li><a href="https://www.youtube.com/" target="_blank"><i class="fa fa-youtube-play"
                aria-hidden="true"></i></a></li>
          <li><a href="https://www.facebook.com/Yes-We-Can-Transportation-LLC-180377320728570" target="_blank"><i
                class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
        </ul>
      </div>
      <p class="copyright">©{{date | date: 'yyyy'}} All Rights Reserved | YesWeCanTransportation LLC</p>
    </div>
  </div>
</footer>